import React from "react";
import useFitText from "use-fit-text";
import logo from "../../logo.png"
const Employeecard = ({ avatar, usrname, admin, inBuilding, cardId, staffid,  }) => {
  const { fontSize, ref } = useFitText()
    const green = "rgba(0, 255, 0, .25)"
    const yellow = "rgba(255, 255, 0, .25)"
    return (
    <div className="emp__card1" id="card" style={{backgroundColor: inBuilding ? green : yellow, borderColor: inBuilding ? green : yellow }}>

      <div className="top">
        <img src={avatar} alt="avatar" onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src=logo;
        }} />
        <h3 ref={ref} id="card-title" style={{fontSize}}>{usrname}</h3>
      </div>
      <div className="bottom">
        <small ref={ref} style={{fontSize}}>{staffid} {cardId}</small>
      </div>
    </div>
  );
};

export default Employeecard;
