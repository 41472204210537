import React, { useState, useEffect, useReducer } from "react";
import { Storage, API, graphqlOperation } from 'aws-amplify';
import {onChangeSTATUS} from "../graphql/subscriptions";
import { studentsByDistrictUpdateAt} from "../graphql/queries";
import moment from "moment-timezone";
const initialState = {students: [], buildingCount: 0}
const today  = moment.tz("America/New_York").toISOString(true).slice(0, 10);
export function reducer(state, action) {
  switch (action.type) {
    case "set":
      return { ...state, students: [...action.studentsData] }
    case "add":
      return { students: [...state.students, action.studentsData] }
    case "update":
      return { ...state, students: action.studentsData }



  }

}

export function useSubscription({students, setStudents, inBuildingCount, setInBuildingCount,building}) {

  const [state, dispatch] = useReducer(reducer, initialState);
  async function fetchData() {
    const studentData = await API.graphql({
      query: studentsByDistrictUpdateAt,
      variables: {
        district: "Mineola",
        buildingCheckInTime: {
          beginsWith: {building: building, checkInTime: today}
        },
        sortDirection: "DESC",
        statusSortDirection: "ASC"
      }
    });
    let count = 0;
    const studentsData = await Promise.all(studentData.data.studentsByDistrictUpdateAt.items.map(async (student, i) => {
      let newStudent = Object.assign({}, student);
      if (student.inBuilding) {
        count++;
      }
      newStudent.Image = await Storage.get(student.imageName);
      return newStudent;
    }));
    setStudents([...studentsData]);
    //setLocalStudents([...studentsData])
    setInBuildingCount(count);
    //setLocalInBuildingCount(count);
    dispatch({type: "set", studentsData});


  }
  useEffect( () => {

    fetchData();
  }, []);

  useEffect(() => {

    const subscriber = API.graphql(graphqlOperation(onChangeSTATUS)).subscribe({
      next: data => {
        const {
          value: {
            data: { onChangeSTATUS }
          }
        } = data
          fetchData();
      }
    });

    return () => subscriber.unsubscribe();
  }, []);

}