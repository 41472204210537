import React from 'react'

const UserList=({students,renderUser})=> {
  return (
    <div className="results">
    {students.map((users) => (
      <span
        key={users.id}
        onClick={() => {
          renderUser(users.id);
        }}
      >
        <h4>{users.firstName + " " + users.lastName}</h4>
        <small>{users.cardId}</small>
      </span>
    ))}
  </div>
  )
}

export default UserList
