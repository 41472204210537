import React from "react";
import "../css/style.css"
import Welcomeinfo from "../components/cards/Welcomeinfo";
import Starts from "../components/cards/Starts";
import Searchbar from "../components/forms/Searchbar";
import Employeecard from "../components/cards/Employeecard";
import Wrapper from "./Wrapper";
import { useNavigate } from "react-router-dom";
const Analytics = ({fetchUsers,setStudents, searchTerm, allStudents, handleSearch,accName, inBuildingCount,currentStudent, setCurrentStudent }) => {
 let navigate = useNavigate();
  const setDash = (student) =>{

    setCurrentStudent(student);

    navigate("../detail")

  }
    if (allStudents.length === 0){
        fetchUsers();
    }
  return (
    <section className="analytics__view">
      <article className="left">
        <Welcomeinfo accName={accName}/>
        <Starts students={allStudents} inBuildingCount={inBuildingCount}/>
        <Searchbar searchTerm={searchTerm} handleSearch={handleSearch} />
        <div className="users-list">
          <div className="user-title">
            <h3>Students </h3>
          </div>
        </div>
      </article>
      <article className="right">
        {allStudents.map((profile, index) => (
          <Wrapper handleClick={()=> {setDash(profile)}} key={profile.id}>
                <Employeecard
                key={index}
                avatar={profile.Image}
                usrname={profile.firstName + " " + profile.lastName}
                staffid={profile.id}
                cardId={profile.cardId}
              />
          </Wrapper>
        ))}
      </article>
    </section>
  );
};

export default Analytics;
