import React from "react";
import {
  BsFillCalendarDateFill,
  BsPieChartFill,
  BsServer,
} from "react-icons/bs";

const Starts = ({ students, users, inBuildingCount }) => {
  //get total number of departments
  //const unique = [...new Set(students.map((item) => item.id))];

  return (
    <div className="starts">
      <div className="starts__card">
        <BsFillCalendarDateFill />
        <h3>{inBuildingCount}</h3>
        <small>Students in today</small>
      </div>
      <div className="starts__card">
        <BsFillCalendarDateFill />
        <h3>{students.length}</h3>
        <small>Total Students</small>
      </div>
    </div>
  );
};

export default Starts;
