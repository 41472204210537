import React, { useRef} from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Userinfo from "../components/cards/Userinfo";
import UserList from "../components/cards/UserList";
import Welcomeinfo from "../components/cards/Welcomeinfo";
import Starts from "../components/cards/Starts";
import Searchbar from "../components/forms/Searchbar";

const Admin = ({
  fetchUsers,
  students,
  currentStudent,
  inBuildingCount,
  setCurrentStudent,
  searchTerm,
  handleSearch,
  accName
}) => {
  const inputEl = useRef("");
  const navigate = useNavigate()
  const handleFilterFunction = () => {
    handleSearch(inputEl.current.value);
    
  };
  if (students.length === 0) {
    fetchUsers();
  }

  const renderUser = (id) => {
    const user = students.filter((element) => {
      return element.id === id;
    });

    setCurrentStudent(JSON.parse(JSON.stringify(user[0])));
    navigate('update')
  };



  return (
    <section className="admin__view">
      <article className="left">
        <Welcomeinfo accName={accName}/>
        <Starts students={students} inBuildingCount={inBuildingCount}/>
        <Searchbar searchTerm={searchTerm} handleSearch={handleSearch} />
        <div className="users-list">
          <div className="user-title">
            <h3>Manage Students</h3>
            <select
              type="option"
              onChange={handleFilterFunction}
              ref={inputEl}
            >
              <option value="all">F ilter</option>
              <option value={true}>In Building</option>
            </select>
          </div>
          <UserList
            students={students}
            renderUser={renderUser}
          />
        </div>
      </article>
      <article className="right">
        <Userinfo currentStudent={currentStudent} />
        <div className="right-manage">
          <NavLink className="btn-new btn" activeclassname="active" to="update">
            Update Info
          </NavLink>
          <NavLink className="btn-new btn" activeclassname="active" to="addnew">
            Add New?
          </NavLink>
        </div>
        <div className="forms-container">
          <Outlet />
        </div>
      </article>
    </section>
  );
};

export default Admin;
