import React from "react";

const Wrapper = ({ children, handleClick }) => {
  return (
    <div onClick={handleClick}>
      {children}
    </div>
  )
}
export default Wrapper
